// percentage/api.js

import PocketBase from "pocketbase";

/**
 * Instance of PocketBase pointing to your application.
 */
const pb = new PocketBase(
  "https://ows4kkks4c8w8skc84o444s4.app-palma.teide.app/"
);

/**
 * Fetches the main data from the "insights" collection and calculates cumulative metrics.
 * @returns {Promise<{ latestItems: Array, prevDataMap: Map, cumulativeMap: Map, globalMax: number }>}
 */
export const fetchTopData = async () => {
  try {
    // Fetch data from the "insights" collection, sorted by 'PentiumNumber' and 'PentiumOrigine'
    const allData = await pb.collection("insights_duplicate").getFullList(50, {
      sort: "-PentiumNumber,-PentiumOrigine",
      filter: "",
      expand: "",
    });

    const uniqueItems = allData; // Already grouped by 'name' and 'PentiumOrigine' date

    // Create a map for historical data
    const prevDataMap = new Map();
    uniqueItems.forEach((item) => {
      prevDataMap.set(item.name, item.history);
    });

    // Calculate cumulative metrics (average PentiumNumber) and determine the global maximum
    const cumulativeMap = new Map();
    let globalMax = 0;
    uniqueItems.forEach((item) => {
      const totalPentium = item.history.reduce(
        (acc, curr) => acc + curr.PentiumNumber,
        0
      );
      const averagePentium =
        item.history.length > 0 ? totalPentium / item.history.length : 0;
      cumulativeMap.set(item.name, averagePentium);
      if (averagePentium > globalMax) {
        globalMax = averagePentium;
      }
    });

    return { latestItems: uniqueItems, prevDataMap, cumulativeMap, globalMax };
  } catch (error) {
    console.error("Error fetching data from PocketBase:", error);
    return {
      latestItems: [],
      prevDataMap: new Map(),
      cumulativeMap: new Map(),
      globalMax: 0,
    };
  }
};
