// InsightsTable.jsx

import React, { useEffect, useState } from "react";
import { fetchTopData } from "./api";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary elements for Chart.js
Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

// Define the color palette for grades
const gradeColors = {
  0: "#ff0000", // Dark Red
  1: "#ff7f00", // Orange
  2: "#ffff00", // Yellow
  3: "#7fff00", // Light Green
  4: "#00ff00", // Dark Green
};

// Function to calculate grade based on cumulative metric
const calculateGrade = (value, max) => {
  if (max === 0) return 0; // Avoid division by zero
  const grade = Math.round((value / max) * 4);
  return Math.min(Math.max(grade, 0), 4); // Ensure grade is between 0 and 4
};

const InsightsTable = () => {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null); // Selected card
  const [historyMap, setHistoryMap] = useState(new Map());
  const [cumulativeMap, setCumulativeMap] = useState(new Map());
  const [globalMax, setGlobalMax] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { latestItems, prevDataMap, cumulativeMap, globalMax } =
          await fetchTopData();
        setInsights(latestItems);
        setHistoryMap(prevDataMap);
        setCumulativeMap(cumulativeMap);
        setGlobalMax(globalMax);
      } catch (error) {
        setError("Error loading data.");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateTrend = (history) => {
    if (history.length > 1) {
      const firstValue = history[0].PentiumNumber;
      const lastValue = history[history.length - 1].PentiumNumber;
      const trend = lastValue - firstValue; // Calculate the point difference
      return trend;
    }
    return 0;
  };

  // Updated function to include point colors based on grade
  const getMiniChartData = (history, insightName) => {
    return {
      labels: history.map((_, index) => index.toString()),
      datasets: [
        {
          data: history.map(
            (item) => item.PentiumNumber - history[0].PentiumNumber
          ),
          borderColor: "rgba(75, 192, 192, 1)", // Line color
          backgroundColor: "transparent",
          fill: false,
          tension: 0, // Straight line
          pointBackgroundColor: history.map(
            () =>
              gradeColors[
                calculateGrade(cumulativeMap.get(insightName) || 0, globalMax)
              ]
          ),
          pointRadius: 3, // Point size
        },
      ],
    };
  };

  const miniChartOptions = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      y: { display: false, beginAtZero: true }, // Ensure y-axis starts at zero
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    elements: {
      point: { radius: 0 }, // Remove default points (custom points defined above)
    },
  };

  const getGlobalTrend = (history) => {
    if (history.length <= 1) return "single";

    const positiveMovements = history.filter(
      (_, i, arr) => i > 0 && arr[i].PentiumNumber > arr[i - 1].PentiumNumber
    ).length;

    const negativeMovements = history.length - 1 - positiveMovements;

    const averageTrend =
      (history[history.length - 1].PentiumNumber - history[0].PentiumNumber) /
      history.length;

    if (averageTrend > 0 && positiveMovements > negativeMovements) {
      return "positive";
    } else if (averageTrend < 0 && negativeMovements > positiveMovements) {
      return "negative";
    } else if (averageTrend === 0) {
      return "noChange";
    } else if (positiveMovements > negativeMovements) {
      return "mediumPositive";
    } else {
      return "mediumNegative";
    }
  };

  const toggleChart = (name) => {
    setSelectedCard((prev) => (prev === name ? null : name)); // Toggle chart visibility
  };

  const renderBatteryIcon = (insightName) => {
    const cumulativeValue = cumulativeMap.get(insightName) || 0;
    const grade = calculateGrade(cumulativeValue, globalMax);
    const color = gradeColors[grade];

    return (
      <svg
        height="24"
        viewBox="0 0 21 21"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0"
      >
        <g fill="none" fillRule="evenodd" transform="translate(2 6)">
          <path
            d="m2.5.5h10c1.1045695 0 2 .8954305 2 2v3c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2v-3c0-1.1045695.8954305-2 2-2z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m3 2h7c.5522847 0 1 .44771525 1 1v2.04629283c0 .55228475-.4477153 1-1 1h-7c-.55228475 0-1-.44771525-1-1v-2.04629283c0-.55228475.44771525-1 1-1z"
            fill={color}
          />
          <path
            d="m16.5 2.5v3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  };

  if (loading)
    return <p className="text-center text-white">Loading data...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="container mx-auto px-4 py-32">
      <h2 className="text-3xl font-bold mb-6 text-white">
        Markets - TCGSE Points
      </h2>
      {/* <h3 className="text-xl font-semibold mb-4 text-white">
        This data is for testing purposes only.
      </h3> */}
      <ul className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {insights.map((insight) => {
          const trend = calculateTrend(historyMap.get(insight.name) || []);
          const history = historyMap.get(insight.name) || [];
          const miniChartData = getMiniChartData(history, insight.name);
          const globalTrend = getGlobalTrend(history);
          const grade = calculateGrade(
            cumulativeMap.get(insight.name) || 0,
            globalMax
          );

          return (
            <li
              key={insight.id}
              className={`bg-blue-900 p-5 rounded-lg shadow-lg transition transform hover:scale-105 hover:shadow-2xl ${
                selectedCard === insight.name ? "col-span-full" : ""
              }`}
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={() => toggleChart(insight.name)}
              >
                <div className="mr-4">{renderBatteryIcon(insight.name)}</div>
                {/* Card name */}
                <span className="text-lg font-semibold text-white mr-auto">
                  {/* If parentheses are detected, render text smaller */}
                  {insight.name.includes("(") ? (
                    <span className="text-base">{insight.name}</span>
                  ) : (
                    insight.name
                  )}
                </span>
                {/* Inline mini graph (only shown if the card is not selected) */}
                {selectedCard !== insight.name && (
                  <div className="flex items-center">
                    <div className="w-24 h-10">
                      <Line
                        data={miniChartData}
                        options={miniChartOptions}
                        width={100}
                        height={40}
                      />
                    </div>
                  </div>
                )}
                {/* Trend points */}
                <span className="text-md px-4 font-medium text-white ml-4">
                  {trend > 0 ? `+${trend}` : trend} {/* Pts */}
                </span>
              </div>
              {/* Show chart if this card is selected */}
              {selectedCard === insight.name && history.length > 0 && (
                <div className="mt-6">
                  <LineChart
                    history={history}
                    cumulativeValue={cumulativeMap.get(insight.name) || 0}
                    globalMax={globalMax}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// Component for the main chart
const LineChart = ({ history, cumulativeValue, globalMax }) => {
  const data = {
    labels: history.map((_, index) => `T ${index + 1}`), // More descriptive labels /* T is days */
    datasets: [
      {
        label: "Points",
        data: history.map(
          (item) => item.PentiumNumber - history[0].PentiumNumber
        ),
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0,
        pointBackgroundColor: history.map(
          () => gradeColors[calculateGrade(cumulativeValue, globalMax)]
        ),
        pointRadius: 5, // Point size
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true }, // Enable tooltips for better UX
    },
    scales: {
      x: {
        display: false,
        title: { display: true, text: "Period" },
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        title: { display: true, text: "Points" },
        beginAtZero: true, // Ensure y-axis starts at zero
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
  };

  return (
    <div className="w-full h-64">
      <Line data={data} options={options} />
    </div>
  );
};

export default InsightsTable;
