// App.js

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/home";
import SocialIcons from "./components/objects/SocialIcons";
import Menu from "./components/ui/Menu/EpicMenu";
import Footer from "./components/ui/Menu/EpicFooter";
import Card from "./components/actions/Card";
/* import Events from "./components/actions/Events"; */
import Trading from "./components/actions/Trading";
import Share from "./components/actions/Share";
import AuthPage from "./components/auth/AuthPage";
import Dashboard from "./components/Views/Dashboard";
import { CardProvider } from "./components/objects/CardContext";
import Cookies from "js-cookie";
import pb from "./components/utils/pocketbase";
import ArticlesList from "./pages/BlogList";
import Articles from "./pages/BlogPost";
import Markets from "./components/DevMarkets/Page";
/* import DevMarkets from "./components/DevMarkets/Page"; */

const App = () => {
  return (
    <CardProvider>
      <Router>
        <MainContent />
      </Router>
    </CardProvider>
  );
};

const MainContent = () => {
  const location = useLocation();
  const isBlogPage = location.pathname.startsWith("/articles");
  const isMarketsPagge = location.pathname.startsWith("/markets");

  return (
    <div
      className={`flex flex-col min-h-screen ${
        isBlogPage ? "bg-white text-black" : "bg-black text-white"
      } ${isMarketsPagge ? "bg-gray-800 ledscreen" : "bg-black text-white"}`}
    >
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/radio" element={<Radio />} /> */}
        <Route path="/articles" element={<ArticlesList />} />
        <Route path="/articles/:slug" element={<Articles />} />
        <Route path="/share/:cards" element={<Share />} />
        <Route
          path="/card/:set/:setCard/:setCardUnderscore"
          element={<Card />}
        />
        <Route path="/trading" element={<Trading />} />
        <Route path="/markets" element={<Markets />} />
        {/*  <Route path="/dev" element={<DevMarkets />} /> */}
        {/* <Route path="/events" element={<Events />} /> */}
        <Route path="/auth" element={<AuthPage />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute component={Dashboard} />}
        />
      </Routes>
      <SocialIcons />
      <Footer />
    </div>
  );
};

// Componente para proteger rutas privadas
const PrivateRoute = ({ component: Component }) => {
  const authData = Cookies.get("pb_auth");

  if (!authData) {
    return <Navigate to="/auth" />;
  }

  const parsedAuthData = JSON.parse(authData);
  pb.authStore.save(parsedAuthData.token, parsedAuthData.model);

  return <Component />;
};

export default App;
